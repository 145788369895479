import React from "react";
import type { ILogoView } from "shared/components/Logo/types";

const LogoView: React.FC<ILogoView> = ({ logoColor, size, isoType }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size! / 4}
			viewBox="0 0 143.146 35.549">
			<g transform="translate(-35 -32)">
				<g transform="translate(35 32)">
					<path
						d="M3.647,30.136v-.02A7.827,7.827,0,0,1,0,23.5V7.832A7.832,7.832,0,0,1,7.832,0H23.5a7.8,7.8,0,0,1,4.738,1.6l.034-.012L33.609,5.33,31.492,6.1l-.364-.026a7.868,7.868,0,0,1,.2,1.765V23.5a7.833,7.833,0,0,1-7.833,7.833H8.6l.436,2.528Z"
						transform="translate(0 0)"
						fill="#9c10ff"
					/>
					<g transform="translate(6.025 4.218)">
						<path
							d="M23.233,29.3H7.568A6.075,6.075,0,0,1,1.5,23.233V7.568A6.075,6.075,0,0,1,7.568,1.5H23.233A6.075,6.075,0,0,1,29.3,7.568V23.233A6.075,6.075,0,0,1,23.233,29.3Z"
							transform="translate(0.265 0.265)"
							fill="#fff"
						/>
						<path
							d="M23.5,27.8a4.308,4.308,0,0,0,4.3-4.3V7.833a4.308,4.308,0,0,0-4.3-4.3H7.833a4.308,4.308,0,0,0-4.3,4.3V23.5a4.308,4.308,0,0,0,4.3,4.3H23.5m0,3.53H7.833A7.833,7.833,0,0,1,0,23.5V7.833A7.832,7.832,0,0,1,7.833,0H23.5a7.833,7.833,0,0,1,7.833,7.833V23.5A7.834,7.834,0,0,1,23.5,31.331Z"
							transform="translate(0 0)"
							fill="#9c10ff"
						/>
					</g>
					<path
						d="M1.834,0H4.588A1.834,1.834,0,0,1,6.419,1.834V4.588A1.834,1.834,0,0,1,4.588,6.419H1.834A1.834,1.834,0,0,1,0,4.588V1.834A1.834,1.834,0,0,1,1.834,0Z"
						transform="translate(14.093 12.731)"
						fill="#9c10ff"
					/>
					<path
						d="M1.834,0H4.588A1.834,1.834,0,0,1,6.419,1.834V4.588A1.834,1.834,0,0,1,4.588,6.419H1.834A1.834,1.834,0,0,1,0,4.588V1.834A1.834,1.834,0,0,1,1.834,0Z"
						transform="translate(14.093 21.901)"
						fill="#9c10ff"
					/>
					<path
						d="M1.834,0H4.588A1.834,1.834,0,0,1,6.419,1.834V4.588A1.834,1.834,0,0,1,4.588,6.419H1.834A1.834,1.834,0,0,1,0,4.588V1.834A1.834,1.834,0,0,1,1.834,0Z"
						transform="translate(24.18 12.731)"
						fill="#9c10ff"
					/>
					<path
						d="M1.834,0H4.588A1.834,1.834,0,0,1,6.419,1.834V4.588A1.834,1.834,0,0,1,4.588,6.419H1.834A1.834,1.834,0,0,1,0,4.588V1.834A1.834,1.834,0,0,1,1.834,0Z"
						transform="translate(24.18 21.901)"
						fill="#9c10ff"
					/>
				</g>
				{!isoType && (
					<g>
						<path
							d="M13.125-17.5v2.75H8.2V0H5.325V-14.75H.375V-17.5Zm9.6,5h2.7V0h-2.7V-1.8A5.058,5.058,0,0,1,18.35.325a5.862,5.862,0,0,1-4.4-1.913A6.5,6.5,0,0,1,12.125-6.25a6.491,6.491,0,0,1,1.825-4.675,5.88,5.88,0,0,1,4.4-1.9,5.079,5.079,0,0,1,4.375,2.1ZM15.95-3.388A3.809,3.809,0,0,0,18.775-2.25,3.809,3.809,0,0,0,21.6-3.388,3.9,3.9,0,0,0,22.725-6.25,3.9,3.9,0,0,0,21.6-9.113a3.809,3.809,0,0,0-2.825-1.138A3.809,3.809,0,0,0,15.95-9.113,3.9,3.9,0,0,0,14.825-6.25,3.9,3.9,0,0,0,15.95-3.388ZM30.925-9.067a1.085,1.085,0,0,0,.693.975,10.177,10.177,0,0,0,1.663.657l1.987.612a3.441,3.441,0,0,1,1.7,1.161,3.3,3.3,0,0,1,.682,2.137A3.319,3.319,0,0,1,36.263-.7,5.711,5.711,0,0,1,32.775.325,6.182,6.182,0,0,1,29.6-.45,4.546,4.546,0,0,1,27.675-2.6L30-3.95a2.652,2.652,0,0,0,2.775,1.8q2.125,0,2.125-1.4a1.109,1.109,0,0,0-.688-.975A9.041,9.041,0,0,0,32.555-5.2l-1.988-.612a3.563,3.563,0,0,1-1.705-1.126A3.082,3.082,0,0,1,28.175-9a3.378,3.378,0,0,1,1.313-2.775,5.057,5.057,0,0,1,3.263-1.05,5.5,5.5,0,0,1,2.763.688,4.677,4.677,0,0,1,1.862,1.913L35.1-8.95a2.361,2.361,0,0,0-2.35-1.425,2.38,2.38,0,0,0-1.3.333A1.083,1.083,0,0,0,30.925-9.067ZM50.925,0H47.7L42.575-5.77V0h-2.7V-17.5h2.7V-6.949l4.85-5.551h3.3l-5.5,6.125Zm15.45-17.5-6.1,10.55V0H57.4V-6.95L51.3-17.5h3.275l4.277,7.746L63.1-17.5ZM75-12.5h2.7V0H75V-1.6A4.256,4.256,0,0,1,71.125.325,4.607,4.607,0,0,1,67.65-1.05a5.213,5.213,0,0,1-1.325-3.775V-12.5h2.7v7.4a2.91,2.91,0,0,0,.75,2.15,2.77,2.77,0,0,0,2.05.75,3.086,3.086,0,0,0,2.3-.887A3.725,3.725,0,0,0,75-5.8Zm12.925-.325a5.862,5.862,0,0,1,4.4,1.913A6.5,6.5,0,0,1,94.15-6.25a6.491,6.491,0,0,1-1.825,4.675,5.88,5.88,0,0,1-4.4,1.9,5.079,5.079,0,0,1-4.375-2.1V5h-2.7V-12.5h2.7v1.8A5.058,5.058,0,0,1,87.925-12.825Zm-3.25,9.438A3.809,3.809,0,0,0,87.5-2.25a3.809,3.809,0,0,0,2.825-1.138A3.9,3.9,0,0,0,91.45-6.25a3.9,3.9,0,0,0-1.125-2.863A3.809,3.809,0,0,0,87.5-10.25a3.809,3.809,0,0,0-2.825,1.138A3.9,3.9,0,0,0,83.55-6.25,3.9,3.9,0,0,0,84.675-3.388Z"
							transform="translate(83.746 58.509)"
							fill={logoColor}
							stroke={logoColor}
							strokeWidth="0.5"
						/>
					</g>
				)}
			</g>
		</svg>
	);
};

export default LogoView;
